<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Bagi Hasil</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Daftar Bagi Hasil</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <ShareDistibutionOverview />

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Riwayat Bagi Hasil</h4>
                        </div>
                        <div class="card-body">
                            <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions"
                                :reload="reloadDataTable" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { endpoint } from "../../host";
import DataTable from "../../components/datatable/DataTable.vue";
import httpService from "../../services/http-service";
import globalService from '../../services/global-service';
import ShareDistibutionOverview from "./ShareDistibutionOverview.vue";

export default {
    components: {
    DataTable,
    ShareDistibutionOverview
},

    created() {
        this.generateDataTableUrl();
    },

    data() {
        return {
            reloadDataTable: false,
            dataTableUrl: null,
            actions: [],
            columnHeaders: [
                {
                    text: "Tanggal",
                    render: (data) => {
                        return globalService.dateFormat2(data['created_at']);
                    },
                },
                {
                    text: "Slot Digunakan",
                    name: "transactionable.slot_used",
                },
                {
                    text: "Jumlah",
                    render: (data) => {
                        return globalService.toIdr(data["amount"]);
                    },
                },
                {
                    text: "Keterangan",
                    name: "description",
                },
            ],
        };
    },

    methods: {
        generateDataTableUrl() {
            this.dataTableUrl = httpService.generateEndpointQuery(endpoint.transaction, {
                transactionable_type: 'ShareDistribution'
            })
        },
    },
};
</script>

<style>
</style>