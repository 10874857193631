<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card crm-widget">
                <div class="card-body p-0">
                    <div class="row row-cols-xxl-2 row-cols-md-2 row-cols-1 g-0">
                        <div class="col">
                            <div class="py-4 px-3">
                                <h5 class="text-muted text-uppercase fs-13">Bagi Hasil Bulan Lalu
                                </h5>
                                <div class="d-flex align-items-center" v-if="meta">
                                    <div class="flex-shrink-0">
                                        <i class="ri-coin-line display-6 text-muted"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0"><span class="counter-value">{{
                                                toIdr(meta['last_month'])
                                        }}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mt-3 mt-md-0 py-4 px-3">
                                <h5 class="text-muted text-uppercase fs-13">Total Bagi Hasil
                                </h5>
                                <div class="d-flex align-items-center" v-if="meta">
                                    <div class="flex-shrink-0">
                                        <i class="ri-coin-fill display-6 text-muted"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0"><span class="counter-value">{{ toIdr(meta['all']) }}</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1">Grafik Bagi Hasil (12 Bulan Terakhir)</h4>
                </div>
                <div class="card-body">
                    <BarChart :chartData="transactionOverviewDataset" :options="options" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { endpoint } from "../../host";
import httpService from "../../services/http-service";
import globalService from '../../services/global-service';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import errorService from '../../services/error-service';
Chart.register(...registerables);

export default {
    components: {
        BarChart,
    },

    created() {
        this.getTransactionOverview();
    },

    data() {
        return {
            meta: null,
            options: {
                // indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false
            },
            transactionOverviewDataset: {
                labels: [],
                datasets: [
                    {
                        label: 'bagi hasil',
                        data: [],
                        backgroundColor: ['#405189'],
                    },
                ],
            },
        };
    },

    methods: {

        async getTransactionOverview() {
            try {
                let res = await httpService.get(endpoint.transaction + '/overview/share-distribution');
                this.meta = res.data.meta;
                let list = res.data.data;
                list.forEach(element => {
                    this.transactionOverviewDataset.labels.push(element['month_name']);
                    this.transactionOverviewDataset.datasets[0].data.push(element['total']);
                });
            } catch (error) {
                errorService.displayError(error);
            }
        },

        toIdr(a) {
            return globalService.toIdr(a);
        }
    },
};
</script>

<style>
</style>